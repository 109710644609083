<template>
	<v-sheet class="inventory" id="inventory" style="height: calc(100vh - 88px)">
		<v-row>
			<v-col md="6" class="my-auto">
				<h1 class="custom-header-blue-text m-0">{{ pageTitle() }}</h1>
			</v-col>
			<v-col md="6" class="text-right">
				<v-btn
					:loading="pageLoading"
					class="white--text mr-2"
					depressed
					color="blue darken-4"
					tile
					:disabled="pageLoading || !formValid"
					v-on:click="updateOrCreate()"
				>
					Save
				</v-btn>
				<v-btn :disabled="pageLoading" depressed tile v-on:click="goBack()"> Cancel </v-btn>
			</v-col>
			<v-col md="12">
				<v-form
					ref="inventoryForm"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="updateOrCreate"
				>
					<v-row>
						<v-col md="8">
							<perfect-scrollbar
								:options="{ suppressScrollX: true }"
								class="scroll custom-box-top-inner-shadow"
								style="max-height: calc(100vh - 160px); position: relative"
							>
								<v-row>
									<v-col md="12" class="py-0">
										<p class="middle-header-background mb-4 px-4">
											Core Information<br />
											<span>
												<v-icon small>mdi-progress-question</v-icon> Specify the primary description for your
												perishables</span
											>
										</p>
									</v-col>
								</v-row>
								<v-row class="px-4">
									<v-col md="4" class="my-auto py-0">
										<label for="name" class="btx-label mt-2 required">Name</label>
									</v-col>
									<v-col md="8" class="py-0">
										<TextInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="name"
											:rules="[vrules.required(inventory.name, 'Name')]"
											:class="{
												required: !inventory.name,
											}"
											placeholder="Name"
											v-model="inventory.name"
										></TextInput>
									</v-col>
									<v-col md="4" class="my-auto py-0">
										<label for="pico-barcode" class="btx-label mt-2">Pico Barcode</label>
									</v-col>
									<v-col md="8" class="py-0">
										<TextInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="pico-barcode"
											placeholder="Pico Barcode"
											v-model="inventory.pico_barcode"
										></TextInput>
									</v-col>
									<template v-if="false">
										<v-col md="4" class="my-auto py-0">
											<label for="model" class="btx-label mt-2">Model Number</label>
										</v-col>
										<v-col md="8" class="py-0">
											<TextInput
												hide-details
												:disabled="pageLoading"
												:loading="pageLoading"
												id="model"
												placeholder="Model Number"
												v-model="inventory.model"
												:rules="[vrules.required(inventory.model, 'Model Number')]"
												:class="{
													required: !inventory.model,
												}"
											></TextInput>
										</v-col>
									</template>
									<v-col md="4" class="my-auto py-0">
										<label for="group" class="btx-label mt-2 required">Category</label>
									</v-col>
									<v-col md="8" class="py-0">
										<AutoCompleteInput
											hide-details
											:items="groupList"
											:disabled="pageLoading"
											:loading="pageLoading"
											id="group"
											:rules="[vrules.required(inventory.group, 'Group')]"
											:class="{
												required: !inventory.group,
											}"
											v-on:change="getSubGroup($event)"
											placeholder="Category"
											v-model="inventory.group"
										></AutoCompleteInput>
									</v-col>
									<v-col md="4" class="my-auto py-0">
										<label for="purchased-on" class="btx-label mt-2 required">Purchased On</label>
									</v-col>
									<v-col md="8" class="py-0">
										<DatePicker
											hide-details
											clearable
											:disabled="pageLoading"
											:loading="pageLoading"
											id="purchased-on"
											placeholder="Purchased On"
											v-model="inventory.purchased_on"
											:rules="[vrules.required(inventory.purchased_on, 'Purchased On')]"
											:class="{
												required: !inventory.purchased_on,
											}"
										></DatePicker>
									</v-col>
									<template v-if="false && subGroupList.length">
										<v-col md="4" class="my-auto py-0">
											<label for="sub-group" class="btx-label mt-2">Sub Group</label>
										</v-col>
										<v-col md="8" class="py-0">
											<AutoCompleteInput
												hide-details
												:items="subGroupList"
												:disabled="pageLoading"
												:loading="pageLoading"
												id="sub-group"
												placeholder="Sub Group"
												v-model="inventory.sub_group"
											></AutoCompleteInput>
										</v-col>
									</template>
									<template v-if="false">
										<v-col md="4" class="my-auto py-0">
											<label for="id-number" class="btx-label mt-2"
												>Identification Number
												<TooltipQuestion>
													<template v-slot:text
														>Your unique identifier for this<br />Perishables. It can also be printed<br />and
														scanned as a barcode.</template
													>
												</TooltipQuestion>
											</label>
										</v-col>
										<v-col md="8" class="py-0">
											<TextInput
												hide-details
												:disabled="pageLoading"
												:loading="pageLoading"
												id="id-number"
												placeholder="Identification Number"
												v-model="inventory.id_number"
											></TextInput>
										</v-col>
									</template>
									<v-col md="4" class="py-0">
										<label for="description" class="btx-label mt-2 required">Description</label>
									</v-col>
									<v-col md="8" class="py-0">
										<TextAreaInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="description"
											placeholder="Description"
											v-model="inventory.description"
											:rules="[vrules.required(inventory.description, 'Description')]"
											:class="{
												required: !inventory.description,
											}"
										></TextAreaInput>
									</v-col>
									<v-col md="4" class="my-auto py-0">
										<label for="contact" class="btx-label mt-2">Contact for Item</label>
									</v-col>
									<v-col md="8" class="py-0">
										<PhoneInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="contact"
											placeholder="Contact for Item"
											v-model="inventory.contact"
										></PhoneInput>
									</v-col>
								</v-row>
								<v-row>
									<v-col md="12" class="py-0">
										<p class="middle-header-background my-4 mt-7 px-4">
											Extended Information<br />
											<span>
												<v-icon small>mdi-progress-question</v-icon> Specify the purchase, location and quantity
												details for your perishables</span
											>
										</p>
									</v-col>
								</v-row>
								<v-row class="px-4">
									<v-col md="4" class="my-auto py-0">
										<label for="price-for-add-stock" class="btx-label mt-2 required"
											>Price For Add Stock
											<TooltipQuestion>
												<template v-slot:text>Default price for adding new stock</template>
											</TooltipQuestion>
										</label>
									</v-col>
									<v-col md="8" class="py-0">
										<PriceInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="price-for-add-stock"
											placeholder="Price For Add Stock"
											v-model="inventory.price_add_stock"
											:rules="[vrules.required(inventory.price_add_stock, 'Purchased On')]"
											:class="{
												required: !inventory.price_add_stock,
											}"
										></PriceInput>
									</v-col>
									<template v-if="false">
										<v-col md="4" class="my-auto py-0">
											<label for="price-for-remove-stock" class="btx-label mt-2"
												>Price For Remove Stock
												<TooltipQuestion>
													<template v-slot:text>Default price for remove stock</template>
												</TooltipQuestion>
											</label>
										</v-col>
										<v-col md="8" class="py-0">
											<PriceInput
												hide-details
												:disabled="pageLoading"
												:loading="pageLoading"
												id="price-for-remove-stock"
												placeholder="Price For Remove Stock"
												v-model="inventory.price_remove_stock"
												:rules="[vrules.required(inventory.price_remove_stock, 'Purchased On')]"
												:class="{
													required: !inventory.price_remove_stock,
												}"
											></PriceInput>
										</v-col>
									</template>
									<v-col md="4" class="my-auto py-0">
										<label for="initial-stock-quantity" class="btx-label mt-2 required"
											>Stock Level
											<TooltipQuestion>
												<template v-slot:text
													>You can also add more stock<br />after creating the perishables</template
												>
											</TooltipQuestion>
										</label>
									</v-col>
									<v-col md="8" class="py-0">
										<QuantityInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="initial-stock-quantity"
											placeholder="Stock Level"
											v-model="inventory.initial_stock"
											:rules="[vrules.required(inventory.initial_stock, 'Stock Level')]"
											:class="{
												required: !inventory.initial_stock,
											}"
										></QuantityInput>
									</v-col>
									<v-col md="4" class="my-auto py-0">
										<label for="reorder-quantity" class="btx-label mt-2 required"
											>Reorder Level
											<TooltipQuestion>
												<template v-slot:text
													>Quantity to be added in Purchase<br />Order for this perishables</template
												>
											</TooltipQuestion>
										</label>
									</v-col>
									<v-col md="8" class="py-0">
										<QuantityInput
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											id="reorder-quantity"
											:rules="[vrules.required(inventory.reorder_quantity, 'Reorder Quantity')]"
											:class="{
												required: !inventory.reorder_quantity,
											}"
											placeholder="Reorder Quantity"
											v-model="inventory.reorder_quantity"
										></QuantityInput>
									</v-col>
									<v-col md="4" class="my-auto py-0">
										<label for="vendor" class="btx-label mt-2 required">Supplier</label>
									</v-col>
									<v-col md="8" class="py-0">
										<AutoCompleteInput
											hide-details
											:items="vendorList"
											:disabled="pageLoading"
											:loading="pageLoading"
											id="vendor"
											placeholder="Supplier"
											v-model="inventory.vendor"
											:rules="[vrules.required(inventory.vendor, 'Supplier')]"
											:class="{
												required: !inventory.vendor,
											}"
										></AutoCompleteInput>
									</v-col>
									<template v-if="false">
										<v-col md="4" class="my-auto py-0">
											<label for="location" class="btx-label mt-2">Location</label>
										</v-col>
										<v-col md="8" class="py-0">
											<AutoCompleteInput
												hide-details
												:items="locationList"
												:disabled="pageLoading"
												:loading="pageLoading"
												id="location"
												placeholder="Location"
												v-model="inventory.location"
												v-on:change="setDefaultLocation($event)"
											></AutoCompleteInput>
										</v-col>
										<v-col md="4" class="my-auto py-0">
											<label for="low-stock-threshold" class="btx-label mt-2"
												>Low Stock Threshold
												<TooltipQuestion>
													<template v-slot:text
														>You will receive an email alert<br />when the total stock quantity<br />becomes less
														than this value</template
													>
												</TooltipQuestion>
											</label>
										</v-col>
										<v-col md="8" class="py-0">
											<QuantityInput
												hide-details
												:disabled="pageLoading"
												:loading="pageLoading"
												id="low-stock-threshold"
												placeholder="Low Stock Threshold"
												v-model="inventory.low_stock"
											></QuantityInput>
										</v-col>
									</template>
								</v-row>
								<template v-if="false">
									<v-row>
										<v-col md="12" class="py-0">
											<p class="middle-header-background my-4 mt-7 px-4">
												Location Specific Thresholds<br />
												<span>
													<v-icon small>mdi-progress-question</v-icon> Specify the low and excess location
													thresholds for your perishables</span
												>
											</p>
										</v-col>
									</v-row>
									<v-row class="px-4">
										<v-col md="12" class="py-0">
											<table width="100%">
												<tr>
													<td width="33.33%">Location</td>
													<td width="33.33%" class="px-2">
														Low Location Threshold
														<TooltipQuestion>
															<template v-slot:text
																>For all locations without specified<br />thresholds, you will receive an<br />email
																alert when stock<br />quantity falls below this value</template
															>
														</TooltipQuestion>
													</td>
													<td width="33.33%">
														Excess Location Threshold
														<TooltipQuestion>
															<template v-slot:text
																>For all locations without specified<br />thresholds, you will receive an<br />email
																alert when stock<br />quantity is higher than this value</template
															>
														</TooltipQuestion>
													</td>
												</tr>
												<tr v-for="(row, index) in stockThresholds" :key="index">
													<td width="33.33%">
														<AutoCompleteInput
															v-if="index == 0"
															hide-details
															:items="locationList"
															:disabled="pageLoading"
															:loading="pageLoading"
															:id="`location-${index}`"
															placeholder="Default Location"
															v-model="row.location"
															readonly
														></AutoCompleteInput>
														<AutoCompleteInput
															v-else
															hide-details
															:items="locationList"
															:disabled="pageLoading"
															:loading="pageLoading"
															:id="`location-${index}`"
															placeholder="Location"
															v-model="row.location"
														></AutoCompleteInput>
													</td>
													<td width="33.33%" class="px-2">
														<QuantityInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															:id="`low-stock-threshold-${index}`"
															placeholder="Low Stock Threshold"
															v-model="row.low_stock"
														></QuantityInput>
													</td>
													<td :class="{ 'd-flex': index > 0 }">
														<QuantityInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															:id="`excess-stock-threshold-${index}`"
															placeholder="Excess Stock Threshold"
															v-model="row.excess_stock"
														></QuantityInput>
														<v-btn
															:disabled="pageLoading"
															v-if="index > 0"
															icon
															small
															class="mt-3 ml-2"
															color="red lighten-1"
															depressed
															tile
															v-on:click="removeLocation(index)"
														>
															<v-icon>mdi-delete</v-icon>
														</v-btn>
													</td>
												</tr>
												<tr>
													<td class="py-3" colspan="3">
														<v-btn
															:disabled="pageLoading"
															text
															color="blue darken-4"
															depressed
															tile
															v-on:click="addLocation()"
														>
															Add Location Specific Thresholds
														</v-btn>
													</td>
												</tr>
											</table>
										</v-col>
										<v-col md="12" class="min-height-40"> </v-col>
									</v-row>
								</template>
							</perfect-scrollbar>
						</v-col>
						<v-col md="4">
							<v-row>
								<v-col md="12" class="my-6 py-0 text-center">
									<ImageUpload
										can-change
										v-model="inventory.image"
										:page-loading="pageLoading"
									></ImageUpload>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-form>
			</v-col>
		</v-row>
	</v-sheet>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { CreateInventory, UpdateInventory, GetInventory } from "@/core/lib/inventory.lib";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TextInput from "@/view/components/TextInput";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import PhoneInput from "@/view/components/Phone";
import PriceInput from "@/view/components/PriceInput";
import QuantityInput from "@/view/components/QuantityInput";
import ImageUpload from "@/view/components/ImageUpload";
import TooltipQuestion from "@/view/components/TooltipQuestion";
import { filter } from "lodash";
import DatePicker from "@/view/components/DatePicker";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";

export default {
	name: "inventory-create",
	title: "Create Perishables",
	data() {
		return {
			uuid: null,
			barcode: null,
			formValid: true,
			pageLoading: false,
			groupList: [],
			subGroupList: [],
			allSubGroupList: [],
			vendorList: [],
			locationList: [],
			stockThresholds: [
				{
					id: null,
					location: null,
					low_stock: null,
					excess_stock: null,
				},
			],
			inventory: {
				name: null,
				model: null,
				group: null,
				sub_group: null,
				id_number: null,
				description: null,
				pico_barcode: null,
				contact: null,
				price_add_stock: null,
				price_remove_stock: null,
				initial_stock: null,
				reorder_quantity: 1,
				vendor: null,
				purchased_on: null,
				location: null,
				low_stock: null,
				image: null,
			},
		};
	},
	components: {
		TextInput,
		PhoneInput,
		PriceInput,
		QuantityInput,
		ImageUpload,
		TooltipQuestion,
		TextAreaInput,
		AutoCompleteInput,
		DatePicker,
	},
	methods: {
		addLocation() {
			this.stockThresholds.push({
				id: null,
				location: null,
				low_stock: null,
				excess_stock: null,
			});
		},
		removeLocation(index) {
			this.stockThresholds.splice(index, 1);
		},
		async updateOrCreate() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.inventoryForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.inventoryForm.validate()) {
				return false;
			}

			const formData = {
				name: this.inventory.name,
				model: this.inventory.model,
				group: this.inventory.group,
				sub_group: this.inventory.sub_group,
				id_number: this.inventory.id_number,
				description: this.inventory.description,
				contact: this.inventory.contact,
				price_add_stock: this.inventory.price_add_stock,
				price_remove_stock: this.inventory.price_remove_stock,
				initial_stock: this.inventory.initial_stock,
				reorder_quantity: this.inventory.reorder_quantity,
				supplier: this.inventory.vendor,
				location: this.inventory.location,
				low_stock: this.inventory.low_stock,
				image: this.inventory.image,
				pico_barcode: this.inventory.pico_barcode,
				purchased_on: this.inventory.purchased_on,
				stock_thresholds: this.stockThresholds,
			};

			try {
				_this.pageLoading = true;

				if (_this.uuid) {
					const { uuid } = await UpdateInventory(_this.uuid, formData);

					_this.$router.replace({
						name: "inventory-detail",
						params: { uuid },
						query: { t: new Date().getTime() },
					});

					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Perishables has been updated." },
					]);
					_this.$store.dispatch(SET_LOCAL_DB);
				} else {
					const { uuid } = await CreateInventory(formData);

					_this.$router.replace({
						name: "inventory-detail",
						params: { uuid },
						query: { t: new Date().getTime() },
					});

					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Perishables has been created." },
					]);
					_this.$store.dispatch(SET_LOCAL_DB);
				}
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		setDefaultLocation(param) {
			this.stockThresholds[0].location = param;
		},
		getSubGroup(param) {
			this.subGroupList = filter(this.allSubGroupList, { group: param });
		},
		getInventory(uuid) {
			GetInventory(uuid)
				.then((data) => {
					this.barcode = data.barcode;
					this.inventory.name = data.name;
					this.inventory.model = data.model;
					this.inventory.group = data.group;
					this.inventory.sub_group = data.sub_group;
					this.inventory.id_number = data.id_number;
					this.inventory.description = data.description;
					this.inventory.contact = data.contact;
					this.inventory.price_add_stock = data.price_add_stock;
					this.inventory.price_remove_stock = data.price_remove_stock;
					this.inventory.initial_stock = data.initial_stock;
					this.inventory.reorder_quantity = data.reorder_quantity;
					this.inventory.vendor = data.supplier;
					this.inventory.location = data.location;
					this.inventory.low_stock = data.low_stock;
					this.inventory.purchased_on = data.purchased_on;
					this.inventory.pico_barcode = data.pico_barcode;
					this.inventory.image = data.image;

					if (data.stock_thresholds && data.stock_thresholds.length) {
						this.stockThresholds = data.stock_thresholds.map((row) => {
							return {
								id: row.id,
								location: row.location,
								pico_barcode: row.low_location,
								excess_stock: row.excess_location,
							};
						});
					}

					this.$nextTick(() => {
						if (this.inventory.location) {
							this.setDefaultLocation(this.inventory.location);
						}

						if (this.inventory.group) {
							this.getSubGroup(this.inventory.group);
						}
					});

					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Perishables", disabled: true },
						{ text: "Update", disabled: true },
						{ text: this.barcode, disabled: true },
					]);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		pageTitle() {
			if (this.uuid) {
				return this.inventory.name;
			}
			return "Create new Perishables";
		},
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
	},
	mounted() {
		this.groupList = this.localDB("groups", []);
		this.allSubGroupList = this.localDB("sub_groups", []);
		this.vendorList = this.localDB("suppliers", []);
		this.locationList = this.localDB("locations", []);

		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Perishables", disabled: true },
			{ text: "Create", disabled: true },
		]);
		const { name, params, query } = this.$route;
		if (query && query.supplier) {
			this.inventory.vendor = query.supplier;
		}
		if (query && query.group) {
			this.inventory.group = query.group;
		}
		if (name === "inventory-update") {
			this.uuid = params.uuid;
			if (!this.uuid) {
				this.goBack();
			}
			this.pageLoading = true;
			this.getInventory(this.uuid);
		}
		if (query && query.duplicate) {
			this.getInventory(query.duplicate);
		}
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
